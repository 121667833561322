<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="500">
      <v-card>
        <v-form ref="es" action="#" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Claim ID: {{modalInfo.PromoClaimID}} for Mobile: {{modalInfo.mobile}}</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
              <v-row>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.CorporateAccountID"
                        label="Account ID"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.CorporateName"
                        label="Corporate"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.loginEmailID"
                        label="Email ID"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.email"
                        label="Email"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.MerchantName"
                        label="Merchant Name"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        :value="`${modalInfo.OperatorID} - ${modalInfo.OperatorName}`"
                        label="Operator"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        :value="`${modalInfo.CarParkID} - ${modalInfo.CarParkName}`"
                        label="Car Park"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.CarParkUserInputCode"
                        label="CP Code"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.loginMobileID"
                        label="Mobile ID"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.mobile"
                        label="Mobile"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.name"
                        label="Name"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.SlotGroupID"
                        label="Slot Group ID"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.SlotID"
                        label="Slot ID"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.AdvertID"
                        label="Adv ID"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.PromoClaimID"
                        label="Claim ID"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.Ad_Title"
                        label="Merchant Reward Name"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.PromoUseStartDate"
                        label="Promo Start Use Date"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.PromoUseEndDate"
                        label="Promo End Use Date"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.PromoUseValidDate"
                        label="Validity"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.CreatedDateTime"
                        label="Collected Date"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.RedeemDateTime"
                        label="Redeemed Date"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.PromoUsed"
                        label="Promo Voucher Status"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                      <span class="text-body-2">Image</span><br/>
                      <img style="border: 1px solid #191716" width="200" :src="modalInfo.Ad_Image_1" />
                  </v-col>
              </v-row>
          </v-container>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import * as moment from "moment";
import * as tz from "moment-timezone";

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            page: 1
        }
    },

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            /*
            this.items.parkerID = this.modalInfo.parkerID;
            this.items.name = this.modalInfo.name;
            this.items.mobile = this.modalInfo.mobile;
            this.items.email = this.modalInfo.email;
            */
        },
    }
  }
</script>